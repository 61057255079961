<template>
  <el-button class="c-importer" @click="dialogVisible = true" icon="el-icon-upload2">
    <el-dialog class="importer" title="Importer des fichiers de sous-titrage" :append-to-body="true" :visible.sync="dialogVisible">
      <el-form>
        <el-form-item>
          <el-upload
            class="upload"
            ref="upload"
            action
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            :file-list="fileList"
            :limit="1"
            :on-change="onFileChange"
            :on-remove="onFileRemove"
            :auto-upload="false"
          >
            <el-button slot="trigger" type="primary" plain>Sélectionner un fichier excel</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="acceptReplacement">
            Remplacer tous les fichiers de sous-titrage en français
          </el-checkbox>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="dialogVisible = false">Annuler</el-button>
        <el-button :disabled="fileList.length == 0 || !acceptReplacement" type="primary" plain @click="importFile">Importer</el-button>
      </span>
    </el-dialog>
  </el-button>
</template>

<script>
import { importFile } from '@/api'

export default {
  name: 'Importer',
  props: {
    onImported: { type: Function, default: () => {} }
  },
  data: function() {
    return {
      file: null,
      fileList: [],
      acceptReplacement: false,
      dialogVisible: false
    }
  },
  methods: {
    async importFile() {
      this.acceptReplacement = false
      this.fileList = []

      var formData = new FormData()
      formData.append('file', this.file.raw, this.file.name)

      try {
        var res = await importFile(formData)
        if (res.ok) {
          const { importedItems } = await res.json()
          this.dialogVisible = false
          this.$notify({
            title: 'Succès',
            message: `Le fichier Excel a été traité avec succès, ${importedItems} sous-titres ont été importés.`,
            type: 'success',
            duration: 3000
          })
          this.onImported()
        } else {
          res.text().then(message => {
            this.$notify.error({
              title: 'Erreur',
              message
            })
          })
        }
      } catch (e) {
        this.$notify.error({
          title: 'Erreur',
          message: res.statusText
        })
      }
    },
    onFileChange(file, fileList) {
      if (file.name.includes('.xlsx') || file.name.includes('.xls')) {
        this.file = file
        this.fileList = fileList
      } else {
        this.fileList = []
        this.$notify.error({
          title: 'Error',
          message: 'Veuillez choisir un fichier excel (.xlsx ou .xls)'
        })
      }
    },
    onFileRemove(_, fileList) {
      this.fileList = fileList
    }
  }
}
</script>

<style lang="scss">
.c-importer {
  > span {
    margin-left: 0 !important;
  }
}
.importer .el-dialog {
  max-width: 600px;
}
</style>
