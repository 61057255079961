<template>
  <el-container>
    <el-main class="c-subtitles">
      <div class="title">Recherche de fichiers de sous-titrage en français</div>

      <el-form @submit.native.prevent="handleGetList" :class="$store.getters.isAdmin ? 'admin' : ''">
        <el-input placeholder="Mot-clé" v-model="searchText" autofocus />
        <el-button-group>
          <el-tooltip content="Lancer la recherche" placement="top" effect="light">
            <el-button @click="handleGetList" type="primary" plain icon="el-icon-search"></el-button>
          </el-tooltip>
          <el-tooltip content="Effacer le mot-clé" placement="top" effect="light">
            <el-button @click="handleResetForm" icon="el-icon-delete"></el-button>
          </el-tooltip>
          <el-tooltip v-if="$store.getters.isAdmin" content="Importer des fichiers de sous-titrage" placement="top" effect="light">
            <importer :on-imported="handleResetForm" />
          </el-tooltip>
        </el-button-group>
      </el-form>

      <el-table
        ref="table"
        v-loading="isLoading"
        :data="subtitles"
        :default-sort="tableSort"
        :empty-text="emptyText"
        @sort-change="handleSortChange"
        height="calc(100vh - 295px)"
      >
        <el-table-column type="index" label="#" width="60" :index="getTableIndex"> </el-table-column>
        <el-table-column prop="name" label="Nom" sortable min-width="280"></el-table-column>
        <el-table-column prop="code" label="Code" sortable width="110"></el-table-column>
        <!-- <el-table-column prop="formatedFirstBroadcast" label="Première diffusion" sortable width="180"></el-table-column> -->
        <el-table-column title="test" prop="duration" label="Durée" sortable width="90"></el-table-column>
        <el-table-column prop="author" label="Rédacteur" sortable width="120"></el-table-column>
        <el-table-column width="120">
          <template slot-scope="scope">
            <order-subtitle v-bind:subtitle="scope.row" />
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <div class="total">{{ total }} fichiers trouvés</div>
        <div class="paging">
          <el-pagination
            layout="prev, pager, next"
            :hide-on-single-page="true"
            :page-size="pageSize"
            :total="total"
            :current-page.sync="page"
            @current-change="handlePaging"
          />
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { getSubtitles } from '@/api'
import OrderSubtitle from '@/components/OrderSubtitle'
import Importer from '@/components/Importer'

export default {
  name: 'Subtitles',
  components: {
    OrderSubtitle,
    Importer
  },
  data: () => ({
    isLoading: false,
    searchText: '',
    subtitles: [],
    defaultSort: 'name',
    sort: 'name',
    page: 1,
    pageSize: 20,
    total: 0
  }),
  created() {
    this.handleGetList()
  },
  computed: {
    emptyText() {
      return this.searchText.length > 0 ? "Le terme recherché n' a pas été trouvé. Essayez à nouveau avec un autre terme." : 'Aucune données'
    },
    tableSort() {
      const [, , direction, prop] = /((-?)([^, ]+))+/i.exec(this.sort)
      return { prop, order: direction === '-' ? 'descending' : 'ascending' }
    }
  },
  methods: {
    async handleGetList() {
      this.isLoading = true
      try {
        var res = await getSubtitles(this.searchText, this.sort, this.page, this.pageSize)
        const { items, itemsCount, page } = await res.json()
        this.subtitles = items
        this.page = page
        this.total = itemsCount
      } catch (e) {
        this.subtitles = []
        this.page = 1
        this.total = 0
      } finally {
        this.isLoading = false
      }
    },
    getTableIndex(index) {
      return (this.page - 1) * this.pageSize + index + 1
    },
    async handleResetForm() {
      this.searchText = ''
      this.sort = this.defaultSort
      this.page = 1
      this.$refs.table.clearSort()
      await this.handleGetList()
    },
    async handlePaging(page) {
      this.page = page
      this.handleGetList()
    },
    handleSortChange({ prop, order }) {
      if (!order) this.sort = this.defaultSort
      else this.sort = (order === 'descending' ? '-' : '') + prop
      this.handleGetList()
    }
  }
}
</script>

<style lang="scss">
.c-subtitles {
  height: calc(100vh - 90px);

  .title {
    font-size: 19px;
    font-weight: 400;
    margin: 10px 0 30px 3px;
  }

  .el-form {
    display: grid;
    grid-template-columns: auto 115px;
    column-gap: 10px;
    justify-items: right;
    margin-bottom: 20px;

    &.admin {
      grid-template-columns: auto 170px;
    }
  }
  .footer {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    height: 30px;
    > .total {
      line-height: 34px;
      color: gray;
      font-size: 0.9em;
    }
    .paging {
      > .el-pagination {
        display: table;
        margin: 0 auto;

        button,
        .el-pager li {
          background: transparent;
        }
      }
    }
  }
}
</style>
